import React from 'react';
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import ContactForm from '../../components/ContactForm';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className='secondaryHero'>
        <h2>Contact Us</h2>
      </section>
      <Container>
        <Row>
          <Col md={5}>
            <section className='formWrapper'>
              <h1>
                Let's Connect,{' '}
                <strong className='cpr'>Send us a message !</strong>{' '}
              </h1>
              <p>
                For inquiries, please kindly drop your contact details and brief
                message here.
              </p>

              <ContactForm />
            </section>
          </Col>
          <Col md={7}>
            <section className='formWrapper'>
              <h1>Our Location</h1>

              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3306.80408093349!2d150.8402048054641!3d-34.023239603780624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1717847540656!5m2!1sen!2snp'
                width='100%'
                height='500'
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
