import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';

const GRAPHQL_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://cms.communityvoice.org.au/';

const cache = new InMemoryCache();
const link = createHttpLink({
  uri: GRAPHQL_URL + '/graphql',
});
const client = new ApolloClient({
  cache,
  link,
});
export default client;
