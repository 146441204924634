import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import Query from '../Query';
function Index() {
  return (
    <Fragment>
      <Query query={CAROUSEL_QUERY} id={'cG9zdDoyMg=='}>
        {({ data: { page } }) => {
          return (
            <>
              <Carousel fade interval={4000}>
                {page?.carousel.carousel.map((a, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <figure className='carouselWrapper'>
                        <img
                          className='carouselWrapper-image'
                          src={a.sourceUrl}
                        />
                      </figure>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </>
          );
        }}
      </Query>
    </Fragment>
  );
}

export default Index;
