import React from 'react';
import { Link } from 'react-router-dom';
import InterestImage from '../../assets/images/interested.png';

const InterestedMember = () => {
  return (
    <>
      <section className='interestedWrapper'>
        <figure className='interestedWrapper-image'>
          <img src={InterestImage} alt='' />

          <section className='interestedWrapper-image-desc'>
            <h1>Interested in becoming a member?</h1>
            <p>
              We are a concerned group of citizens and advocates within our
              local government area who are committed to promoting transparency,
              accountability, and genuine community participation in the
              decision-making processes that affect our community's well-being.
            </p>

            <a href='/application'>
              <button className='bton bton--lg bton--primary'>
                Become a member
              </button>
            </a>
          </section>
        </figure>

        <section>
          <section className='interestedWrapper-red'></section>
        </section>
      </section>
    </>
  );
};

export default InterestedMember;
