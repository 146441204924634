import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Widget() {
  return (
    <Container>
      <section className='flex-between widgetWrapper'>
        <h2>Join Us today !! Be a part of change, for better societies </h2>
        <Link to='/contact-us' rel='noreferrer noopener'>
          <button className='bton bton--lg bton--primary'>
            Contact&nbsp;Us
          </button>
        </Link>
      </section>
    </Container>
  );
}

export default Widget;
