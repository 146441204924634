import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, FloatingLabel, Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
const ApplicationForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [accept, setAccept] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(null);
  const [sending, setSending] = useState(false);

  const [memberForm, setMemberForm] = useState({
    fullname: '',
    company: '',
    email: '',
    companyAddress: '',
    city: '',
    phone: '',
    applicationNumber: '',
    lisenceNumber: '',
    startingDate: '',
  });
  const captchaRef = useRef(null);
  const [validate, setValidate] = useState({
    fullname: false,
    company: false,
    email: false,
    companyAddress: false,
    city: false,
    phone: false,

    startingDate: false,
  });
  const {
    fullname,
    company,
    email,
    companyAddress,
    city,
    phone,

    applicationNumber,
    lisenceNumber,
    startingDate,
  } = memberForm;

  const validateForm = async () => {
    var isValid = true;
    if (fullname.trim() === '') {
      setValidate((prev) => ({ ...prev, fullname: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, fullname: false }));
    }
    if (company.trim() === '') {
      setValidate((prev) => ({ ...prev, company: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, company: false }));
    }
    if (email.trim() === '') {
      setValidate((prev) => ({ ...prev, email: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, email: false }));
    }

    if (companyAddress.trim() === '') {
      setValidate((prev) => ({ ...prev, companyAddress: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, companyAddress: false }));
    }

    if (city.trim() === '') {
      setValidate((prev) => ({ ...prev, city: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, city: false }));
    }

    if (phone.trim() === '') {
      setValidate((prev) => ({ ...prev, phone: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, phone: false }));
    }

    if (startingDate.trim() === '') {
      setValidate((prev) => ({ ...prev, startingDate: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, startingDate: false }));
    }

    return isValid;
  };

  const sendMail = async () => {
    const isValid = await validateForm();
    if (isValid) {
      const token = captchaRef.current.getValue();
      setSending(true);
      const response = await axios.post(
        'https://backend.hamiltondeca.com/api/send/memebership',
        {
          ...memberForm,
          token,
        }
      );
      if (response.data.status === 'success') {
        setSentSuccess(true);
        captchaRef.current.reset();
        setMemberForm({
          fullname: '',
          company: '',
          email: '',
          companyAddress: '',
          city: '',
          phone: '',

          applicationNumber: '',
          lisenceNumber: '',
          startingDate: '',
        });
        setSending(false);
      } else {
        sentSuccess(true);
        setSending(false);
      }
    }
  };

  return (
    <div>
      <Container>
        <Row className='justify-content-center'>
          <Col md={6}>
            <section className='applicationHolder'>
              <h2>Membership Application Form</h2>

              <Row>
                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Full Name'
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={fullname}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          fullname: e.target.value,
                        });
                      }}
                    />
                    {validate.fullname && (
                      <small style={{ color: 'red' }}>
                        Fullname is required
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Company Name'
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={company}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          company: e.target.value,
                        });
                      }}
                    />
                    {validate.company && (
                      <small style={{ color: 'red' }}>
                        Company Name is required
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Company Address'
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={companyAddress}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          companyAddress: e.target.value,
                        });
                      }}
                    />
                    {validate.companyAddress && (
                      <small style={{ color: 'red' }}>
                        Company Address is required
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='City/ Postal Code'
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={city}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          city: e.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Phone'
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={phone}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          phone: e.target.value,
                        });
                      }}
                    />
                    {validate.phone && (
                      <small style={{ color: 'red' }}>Phone is required</small>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Email address'
                    className='mt-3'
                  >
                    <Form.Control
                      type='email'
                      placeholder='data'
                      value={email}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          email: e.target.value,
                        });
                      }}
                    />
                    {validate.email && (
                      <small style={{ color: 'red' }}>Email is required</small>
                    )}
                  </FloatingLabel>

                  <Form.Text className='text-muted'>
                    notices are sent by email
                  </Form.Text>
                </Col>
                {/* <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label="Master's Application Number"
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={applicationNumber}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          applicationNumber: e.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>

                  <Form.Text className='text-muted'>if applicable</Form.Text>
                </Col>
                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label="Contractor's Lisence Number"
                    className='mt-3'
                  >
                    <Form.Control
                      type='text'
                      placeholder='data'
                      value={lisenceNumber}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          lisenceNumber: e.target.value,
                        });
                      }}
                    />
                  </FloatingLabel>

                  <Form.Text className='text-muted'>if applicable</Form.Text>
                </Col>
                <Col md={12}>
                  <FloatingLabel
                    controlId='floatingInput'
                    label='Company Starting Date'
                    className='mt-3 mb-3'
                  >
                    <Form.Control
                      type='date'
                      placeholder='data'
                      value={startingDate}
                      onChange={(e) => {
                        e.preventDefault();
                        setMemberForm({
                          ...memberForm,
                          startingDate: e.target.value.toString().split('T')[0],
                        });
                      }}
                    />
                    {validate.startingDate && (
                      <small style={{ color: 'red' }}>
                        Company Starting Date is required
                      </small>
                    )}
                  </FloatingLabel>
                </Col> */}
                {/* <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                /> */}
                <Col md={12}>
                  <Form.Check
                    className='mt-5'
                    aria-label='option 1'
                    checked={accept}
                    onChange={(e) => setAccept(!accept)}
                    label=' some condition '
                  />
                </Col>
                {accept && !sending ? (
                  <button
                    type='submit'
                    className='bton bton--primary bton--md mt-3'
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   sendMail();
                    // }}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type='submit'
                    disabled={true}
                    className='bton bton--primary bton--md mt-3 disabledButton'
                  >
                    Submit
                  </button>
                )}
              </Row>

              {sentSuccess !== null && (
                <section className='feedbackSection'>
                  {sentSuccess === true && (
                    <div className='feedbackSection-success'>
                      Form Submitted SuccessFully !!
                    </div>
                  )}
                  {sentSuccess === false && (
                    <div className='feedbackSection-failure'>
                      Something Went Wrong !! Try Again
                    </div>
                  )}
                </section>
              )}
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApplicationForm;
