import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import BlogsLoader from '../../components/BlogsLoader';
import InterestedMember from '../../components/InterestedMember';

function Members() {
  const { slug } = useParams();

  //query for nav dropdown

  const GET_MEMBERS = gql`
    query Members_list($id: ID!) {
      member(id: $id, idType: SLUG) {
        title
        memberDetails {
          member {
            description
            name
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  const { loading, data, error } = useQuery(GET_MEMBERS, {
    variables: { id: slug },
  });

  if (error) return <h1>Something went wrong</h1>;

  return (
    <section className='membersWrapper'>
      <section className='secondaryHero'>
        <h2>Members list of {data?.member.title}</h2>
      </section>
      <Container>
        {loading || data === undefined ? (
          <BlogsLoader />
        ) : (
          <>
            {data?.member?.memberDetails?.member?.length > 0 ? (
              <Row>
                {data?.member?.memberDetails?.member?.map((member, index) => (
                  <Col lg={4} xl={3} sm={12} key={index}>
                    <section className='memberCard'>
                      <img
                        className='memberCard-image'
                        src={member.image.sourceUrl}
                        alt={member.title}
                        loading='lazy'
                      />
                      <div className='memberCard-name'>{member.name}</div>
                      <div className='memberCard-description'>
                        {member.description}
                      </div>
                    </section>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className='noMembers'>No members found !!</div>
            )}
          </>
        )}

        <InterestedMember />
      </Container>
    </section>
  );
}

export default Members;
