import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselHero from '../../components/CarouselHero/index';
import Widget from '../../components/Widget';
import { Link } from 'react-router-dom';
import Query from '../../components/Query';
import { POST_QUERY } from '../../queries/posts';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import InterestedMember from '../../components/InterestedMember';

const Home = () => {
  return (
    <>
      {' '}
      <section role='hero'>
        <div className='herocontainer'>
          <CarouselHero />
          <section className='carCaption'>
            <h1 className='carCaption-title'>
              Empowering Our Community, Shaping Our Future
            </h1>
            <div>
              <Link to='/application'>
                <button className='bton bton--md bton--primary mt-3 ctaButton'>
                  Membership
                </button>
              </Link>
              <Link to='/contact-us'>
                <button className='bton bton--md bton--ghost mt-3'>
                  Donate&nbsp;Now
                </button>
              </Link>
            </div>
          </section>
        </div>
      </section>
      <section className='whoWrapper'>
        <Container>
          <h1 className='whoWrapper-title'>Who We Are </h1>

          <Query query={POST_QUERY} id={'cG9zdDoxMDg='}>
            {({ data: { post } }) => {
              return (
                <p
                  className='whoWrapper-desc'
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
              );
            }}
          </Query>
        </Container>
      </section>
      <Container>
        <section className='aboutWrapper'>
          <header className='headTitle'>
            {/* <div className='headTitle-sub'>WHO is HDEAC ?</div> */}
            <h1 className='headTitle-main'>What We Want to Achieve </h1>
          </header>

          <Row className='justify-content-between'>
            <Col md={6} sm={12}>
              <section className='aboutContent'>
                <Query query={POST_QUERY} id={'cG9zdDoxMTA='}>
                  {({ data: { post } }) => {
                    return (
                      <p
                        className='missVisDisc'
                        dangerouslySetInnerHTML={{ __html: post.content }}
                      />
                    );
                  }}
                </Query>
              </section>
            </Col>

            <Col md={5} sm={12}>
              <Query query={CAROUSEL_QUERY} id={'cG9zdDoxMTY='}>
                {({ data: { page } }) => {
                  return (
                    <>
                      {page?.carousel.carousel.map((a, index) => {
                        return (
                          <figure key={index} className='aboutImage'>
                            <img src={a.sourceUrl} alt={a.title} />
                          </figure>
                        );
                      })}
                    </>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </section>
        <InterestedMember />
        <section className='statsWrapper'>
          <Row className='justify-content-center'>
            <Col md={2} sm={12}>
              <article>
                <div className='statsWrapper-number'>100 +</div>
                <div className='statsWrapper-label'>members</div>
              </article>
            </Col>
            <Col md={2} sm={12}>
              <article>
                <div className='statsWrapper-number'>20 +</div>
                <div className='statsWrapper-label'>programs</div>
              </article>
            </Col>
            <Col md={2} sm={12}>
              <article>
                <div className='statsWrapper-number'>15 +</div>
                <div className='statsWrapper-label'>Events</div>
              </article>
            </Col>
          </Row>
        </section>
      </Container>
      <section className='charityWrapper'>
        <Container>
          <Row className='mt-5 mb-5 justify-content-between'>
            <Col md={8}>
              <Row>
                <Query query={CAROUSEL_QUERY} id={'cG9zdDo5OQ=='}>
                  {({ data: { page } }) => {
                    return (
                      <>
                        {page?.carousel.carousel.map((a, index) => {
                          return (
                            <Col md={4} key={index}>
                              <figure className='charityWrapper-image'>
                                <img src={a.sourceUrl} alt='' />
                              </figure>
                            </Col>
                          );
                        })}
                      </>
                    );
                  }}
                </Query>
              </Row>
            </Col>
            <Col md={4} xs={{ order: 'first' }} sm={{ order: 'last' }}>
              <h2 className='charityWrapper-title'> OUR Mission:</h2>

              <Query query={POST_QUERY} id={'cG9zdDoxMTI='}>
                {({ data: { post } }) => {
                  return (
                    <p
                      className='missVisDisc'
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='charityWrapper'>
        <Container>
          <Row className='mt-5 mb-5 justify-content-between'>
            <Col md={4}>
              <h2 className='charityWrapper-title'> OUR VISION:</h2>

              <Query query={POST_QUERY} id={'cG9zdDoxMTQ='}>
                {({ data: { post } }) => {
                  return (
                    <p
                      className='missVisDisc'
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                  );
                }}
              </Query>
            </Col>

            <Col md={8}>
              <Row>
                <Query query={CAROUSEL_QUERY} id={'cG9zdDoxMDM='}>
                  {({ data: { page } }) => {
                    return (
                      <>
                        {page?.carousel.carousel.map((a, index) => {
                          return (
                            <Col md={4} key={index}>
                              <figure className='charityWrapper-image'>
                                <img src={a.sourceUrl} alt='' />
                              </figure>
                            </Col>
                          );
                        })}
                      </>
                    );
                  }}
                </Query>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <WhatWeDo /> */}
      <Widget />
    </>
  );
};

export default Home;
