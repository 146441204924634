import React from 'react';
import { Route, Routes } from 'react-router-dom';

//components

import Footer from '../../components/Footer';
import NavBar from '../../components/Navbar';

//pages
import Home from '../Home';
import Contact from '../Contact';
import ApplicationForm from '../ApplicationForm';
import News from '../News';
import BlogPage from '../BlogPage';
import Gallery from '../Gallery';
import Members from '../Members';
import Vote from '../Vote';

const App = () => {
  return (
    <>
      <NavBar />
      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/application' element={<ApplicationForm />} />
            <Route path='/news' element={<News />} />
            <Route path='/vote' element={<Vote />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/news/:slug' element={<BlogPage />} />
            <Route path='/members/:slug' element={<Members />} />
          </Routes>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default App;
