import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import Query from '../../components/Query';
function Vote() {
  return (
    <div>
      <section className='secondaryHero'>
        <h2>Learn how to vote</h2>
      </section>
      <Container>
        <Query query={CAROUSEL_QUERY} id={'cG9zdDoxNDA='}>
          {({ data: { page } }) => {
            return (
              <>
                {page?.carousel.carousel.map((a) => {
                  return (
                    <section className='VoteImage'>
                      <img src={a.sourceUrl} alt='' />
                    </section>
                  );
                })}
              </>
            );
          }}
        </Query>
      </Container>
    </div>
  );
}

export default Vote;
